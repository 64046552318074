/**
 * Session Status
 */
export var SessionStatus;
(function (SessionStatus) {
  /** JSDoc */
  SessionStatus["Ok"] = "ok";
  /** JSDoc */
  SessionStatus["Exited"] = "exited";
  /** JSDoc */
  SessionStatus["Crashed"] = "crashed";
  /** JSDoc */
  SessionStatus["Abnormal"] = "abnormal";
})(SessionStatus || (SessionStatus = {}));
