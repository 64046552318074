import { defaultInterpolationFormat, I18NEXT_SERVICE, I18NextModule, ITranslationService } from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import XHR from 'i18next-http-backend';
import { environment } from '@src/environments/environment';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import localeEn from '@angular/common/locales/en';
import localeVi from '@angular/common/locales/vi';
import localeZht from '@angular/common/locales/zh-Hant';
import localeId from '@angular/common/locales/id';
import localeBe from '@angular/common/locales/fr-BE';
import { Language } from '@core/models/language.model';
import { FR_TRADITIONAL_LANG, JP_LANG, VI_LANG, ZH_TRADITIONAL_LANG, ID_LANG } from '@core/config/const/language';

export const i18nextOptions = {
  whitelist: ['en', 'ja', 'vi', 'zh-Hant', 'id', 'fr-BE'],
  fallbackLng: environment.translation_reference_lng,
  debug: false,
  returnEmptyString: true,
  ns: [
    'all',
    'account',
    'ad_unit',
    'ads_txt',
    'amp',
    'apps',
    'aso',
    'ats',
    'cwv',
    'import',
    'payment',
    'pwa',
    'report',
    'site',
    'sns',
    'third-party-tag',
    'upr',
    'user_setting',
    'web_stories',
    'pages',
    'ads_txt',
    'game',
    'exchange_rate',
    'dmp',
    'mediation',
    'vcpm',
    'short_video',
    'ecpm',
    'ai_chatbot',
  ],
  defaultNS: 'all',
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
  },
  // backend plugin options
  backend: environment.translation_use_local_files
    ? {
        loadPath: 'assets/locales/{{lng}}/{{ns}}.json',
      }
    : {
        projectId: environment.translation_project_id,
        apiKey: environment.translation_api_key,
        referenceLng: environment.translation_reference_lng,
        // NOTE: create a version from the dashboard and add the version here, then we can merge versions
        // version: 'staging',
        // version - defaults to latest
        version: environment.translation_version,

        // private - set to true if you version on locize is set to use private publish
        private: false,

        // hostnames that are allowed to create, update keys
        // please keep those to your local system, staging, test servers (not production)
        // can be array of allowed hosts or a function (hostname) => { return true; // or false if not allowed }
        allowedAddOrUpdateHosts: ['localhost'],
        // save missing only in development
        saveMissing: !environment.production,
      },
  // lang detection plugin options
  detection: {
    // order and from where user language should be detected
    order: ['localStorage', 'sessionStorage'],

    // keys or params to lookup language from
    lookupLocalStorage: 'lang',

    // cache user language on
    caches: ['localStorage', 'sessionStorage'],
  },
};

export function translationInit(i18next: ITranslationService) {
  return () => {
    return environment.translation_use_local_files
      ? i18next
          .use(XHR)
          .use<any>(LanguageDetector)
          // @ts-ignore
          .init(i18nextOptions)
      : i18next
          .use(Backend)
          .use<any>(LanguageDetector)
          // @ts-ignore
          .init(i18nextOptions);
  };
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: translationInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

export function registerAngularLocales(lang?: Language) {
  /*
    IMPORTANT:
    https://angular.io/guide/i18n#format-data-based-on-locale
   */
  switch (lang.id) {
    case JP_LANG.id:
      registerLocaleData(localeJa);
      break;
    case VI_LANG.id:
      registerLocaleData(localeVi);
      break;
    case ZH_TRADITIONAL_LANG.id:
      registerLocaleData(localeZht);
      break;
    case FR_TRADITIONAL_LANG.id:
      registerLocaleData(localeBe);
      break;
    case ID_LANG.id:
      registerLocaleData(localeId);
      break;
    default:
      registerLocaleData(localeEn);
  }
}
