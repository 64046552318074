/** JSDoc */
// eslint-disable-next-line import/export
export var Severity;
(function (Severity) {
  /** JSDoc */
  Severity["Fatal"] = "fatal";
  /** JSDoc */
  Severity["Error"] = "error";
  /** JSDoc */
  Severity["Warning"] = "warning";
  /** JSDoc */
  Severity["Log"] = "log";
  /** JSDoc */
  Severity["Info"] = "info";
  /** JSDoc */
  Severity["Debug"] = "debug";
  /** JSDoc */
  Severity["Critical"] = "critical";
})(Severity || (Severity = {}));
// eslint-disable-next-line @typescript-eslint/no-namespace, import/export
(function (Severity) {
  /**
   * Converts a string-based level into a {@link Severity}.
   *
   * @param level string representation of Severity
   * @returns Severity
   */
  function fromString(level) {
    switch (level) {
      case 'debug':
        return Severity.Debug;
      case 'info':
        return Severity.Info;
      case 'warn':
      case 'warning':
        return Severity.Warning;
      case 'error':
        return Severity.Error;
      case 'fatal':
        return Severity.Fatal;
      case 'critical':
        return Severity.Critical;
      case 'log':
      default:
        return Severity.Log;
    }
  }
  Severity.fromString = fromString;
})(Severity || (Severity = {}));
